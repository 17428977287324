import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Modal } from 'antd';

import { TfaChooseMethod } from '@organisms/personal-profile/ProfileTfa';

import { fieldValidation, fieldBlur, fieldChange, getFields, responseError } from 'FIELDS';
import { FormSuccess, FormError } from 'IMAGE';
import { tConsume } from 'TRANSLATION';
import defaults from 'DEFAULTS';

class ConfirmationWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      redirect: '',
    };

    this.handleFieldChange = fieldChange.bind(this, this);
    this.handleFieldBlur = fieldBlur.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.getFields = getFields.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  closeModal(result) {
    if (this.props.toggleModal) {
      this.props.toggleModal(false, result);
    }
  }

  handleSubmit({ tfa_code, tfa_method }) {
    const { loading } = this.state;
    const { link, data, redirect, returnResult } = this.props;

    if (!loading && this.fieldValidation()) {
      this.setState({ loading: true });

      data.tfa_code = tfa_code;
      data.tfa_method = tfa_method;

      axios(link.AXIOS('POST', data))
        .then((response) => {
          if (this._mounted) {
            this.setState({ loading: false });

            if ([200, 201].indexOf(response.status) >= 0) {
              this.renderPopup('success');
              this.closeModal(returnResult ? response : null);

              if (redirect) {
                this.setState({ redirect: redirect });
              }
            }
          }
        })
        .catch((error) => {
          if (this._mounted) {
            this.setState({ loading: false }, () => this.responseError(error));

            if (error.response && [400].indexOf(error.response.status) >= 0) {
              this.renderPopup('error');
              this.closeModal();
            }
          }
        });
    }
  }

  renderPopup(status = 'error') {
    const { IconError, textError, IconSuccess, textSuccess } = this.props;

    let text = textError;
    let Icon = IconError;

    if (status === 'success') {
      text = textSuccess;
      Icon = IconSuccess;
    }

    return Modal.info({
      title: null,
      icon: false,
      autoFocusButton: true,
      okButtonProps: {
        size: defaults.antd.button.size,
        block: true,
      },
      content: (
        <div className={'align-center'} style={{ margin: '50px 0' }}>
          <Icon height={100} style={{ margin: '0 auto 50px' }} />
          {text}
        </div>
      ),
    });
  }

  render() {
    const { redirect } = this.state;
    const { userType, className, personalData, children } = this.props;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <>
        {children && <div className={className}>{children}</div>}

        <TfaChooseMethod
          type={'get'}
          userType={userType}
          preferred={personalData.preferred_2fa}
          methods={{ tfa_google: personalData.tfa_google, tfa_phone: personalData.tfa_phone }}
          successCallback={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ personalData: state.personal.data });

export default tConsume(connect(mapStateToProps)(ConfirmationWrapper));

ConfirmationWrapper.propTypes = {
  userType: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  toggleModal: PropTypes.func,
  data: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  IconSuccess: PropTypes.elementType,
  textSuccess: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  IconError: PropTypes.elementType,
  textError: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  personalData: PropTypes.object,
  returnResult: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ConfirmationWrapper.defaultProps = {
  IconSuccess: FormSuccess,
  IconError: FormError,
  returnResult: false,
};
