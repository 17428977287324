const NUMBER_RULES = {
  MIN: 'min',
  MAX: 'max',
  MORE_THAN: 'moreThan',
  LESS_THAN: 'lessThan',
  LENGTH: 'length',
};

const STRING_RULES = {
  MIN: 'min',
  MAX: 'max',
};

export { NUMBER_RULES, STRING_RULES };
