import isRequiredError from './required';
import lengthError from './length';
import isNumericError from './isNumeric';
import isEmailError from './isEmail';
import isUrlError from './isUrl';
import isPercentsError from './isPercents';
import isPhoneError from './isPhone';
import isPasswordError from './password';
import sameAsError from './sameAs';
import emptySetError from './emptySet';
import addonError from './addon';
import isPostalCodeError from './isPostalCode';
import isOwnershipStakeError from './isOwnershipStake';
import isCurrencyError from './isCurrency';
import isCryptoCurrencyError from './isCryptoCurrency';

export default {
  isRequiredError,
  lengthError,
  isEmailError,
  isUrlError,
  isPercentsError,
  isPhoneError,
  isPasswordError,
  sameAsError,
  emptySetError,
  addonError,
  isNumericError,
  isPostalCodeError,
  isOwnershipStakeError,
  isCurrencyError,
  isCryptoCurrencyError,
};
