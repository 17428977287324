import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { TfaQr } from '@organisms/personal-profile/ProfileTfa';
import { TfaPhone } from '@organisms/personal-profile/ProfileTfa';

import { Strings } from 'HELPERS';
import { tConsume } from 'TRANSLATION';

const TfaChooseMethod = ({
  type,
  userType,
  email,
  password,
  preferred,
  methods,
  successCallback,
  errorCallback,
  t,
}) => {
  const [activeMethod, setActiveMethod] = useState(null);
  const actualMethods = t('keys.tfa').filter((item) => methods[item.value]);
  const anotherMethod =
    activeMethod && actualMethods.find((item) => item.short_value !== activeMethod);
  let TfaComponent = null;

  useEffect(() => {
    setActiveMethod(preferred);
  }, []);

  if (activeMethod === 'google') {
    TfaComponent = TfaQr;
  }

  if (activeMethod === 'phone') {
    TfaComponent = TfaPhone;
  }

  return (
    <div className={'chooseTfa'}>
      {activeMethod && (
        <TfaComponent
          type={type}
          userType={userType}
          email={email}
          password={password}
          successCallback={successCallback}
          errorCallback={errorCallback}
        />
      )}

      <div className={'chooseTfa_another'}>
        {anotherMethod && (
          <p className={'textDescription'}>
            <Strings.CompileComponent
              str={t('component.tfa.chose_another')}
              replace={[
                <Button
                  type={'link'}
                  key={0}
                  className={'chooseTfa_another_item'}
                  onClick={() => setActiveMethod(anotherMethod.short_value)}
                >
                  {t(`component.tfa.${anotherMethod.value}_title`)}
                </Button>,
              ]}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default tConsume(TfaChooseMethod);

TfaChooseMethod.propTypes = {
  type: PropTypes.oneOf(['bind', 'get', 'check']),
  userType: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  email: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  password: function (props, propName, componentName) {
    if (
      props['type'] === 'check' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}. Validation failed.`);
    }
  },
  preferred: PropTypes.string.isRequired,
  methods: PropTypes.object,
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
};
