import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon, Divider } from 'antd';
import { connect } from 'react-redux';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import getFormData from 'get-form-data';
import axios from 'axios';
import moment from 'moment-timezone';

import { fetchAllPersonalData } from '@features/personalSlice';
import { getUserToken } from '@features/userTokenSlice';
import notification from '@helps/notification';
import Wrapper from '@molecules/wrappers/RobotWrapper';
import RegistrationBlockWrapper from '@molecules/wrappers/RegistrationBlockWrapper';
import verificationController from '@common/controllers/verification';
import { TfaChooseMethod } from '@organisms/personal-profile/ProfileTfa';

import { fieldValidation, fieldBlur, fieldChange, responseError } from 'FIELDS';
import { InputsSet, RadioSet, PasswordSet } from 'INPUTS';
import api from 'API';
import defaults from 'DEFAULTS';
import { Strings } from 'HELPERS';

class Authorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personalDataLoaded: true,
      loading: false,
      counter: 0,
      attemptKey: 'authAttempt',
      attemptWaitingKey: 'authWaiting',
      data: {},
      user_type: {
        name: 'user_type',
        value: 'individual',
        label: false,
        keys: [],
      },
      email: {
        name: 'email',
        type: 'email',
        required: true,
        autoComplete: 'email',
        placeholder: true,
        description: false,
      },
      password: {
        name: 'password',
        type: 'password',
        autoComplete: 'current-password',
        required: true,
        placeholder: true,
      },
      isTfaVerification: false,
      preferred_2fa: null,
      tfa_google: null,
      tfa_phone: null,
    };

    this._mounted = false;

    this.fieldChange = fieldChange.bind(this, this);
    this.handleFieldBlur = fieldBlur.bind(this, this);
    this.fieldValidation = fieldValidation.bind(this, this);
    this.responseError = responseError.bind(this, this);

    this.handleFieldChange = this.handleFieldChange.bind(this, this);
    this.getUserTypes = this.getUserTypes.bind(this);
    this.isAccountType = this.isAccountType.bind(this);
    this.multipleAccountTypes = this.multipleAccountTypes.bind(this);
    this.successLogin = this.successLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.failureLogin = this.failureLogin.bind(this);
    this.cancelTfa = this.cancelTfa.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    sessionStorage.clear();

    if (this.isAccountType() && this.multipleAccountTypes()) {
      this.getUserTypes();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleFieldChange(context, item) {
    if (item.name === 'user_type') {
      this.props.history.push(`/${item.value}`);
    }

    fieldChange(context, item);
  }

  getUserTypes() {
    const { t, userType } = this.props;
    const { user_type } = this.state;

    const userTypeKeys = defaults.variables.account_types.map((type) =>
      t('keys.account_types').find((item) => item.value === type)
    );

    if (this._mounted) {
      this.setState({
        user_type: {
          ...user_type,
          keys: userTypeKeys,
          value: userType,
        },
      });
    }
  }

  isAccountType() {
    const { userType } = this.props;
    return defaults.variables.account_types.includes(userType);
  }

  multipleAccountTypes() {
    return defaults.variables.account_types.length > 1;
  }

  async successLogin(loginResponse) {
    const { userType, getUserToken, fetchAllPersonalData } = this.props;

    if (loginResponse.data.token) {
      this.setState({ personalDataLoaded: false });

      getUserToken({
        ...loginResponse.data,
        tokenType: userType,
      });

      await fetchAllPersonalData();
      this.setState({ personalDataLoaded: true });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    const { attemptKey, attemptWaitingKey } = this.state;
    const { userType, t } = this.props;
    const blockedUntil = localStorage.getItem(attemptWaitingKey);

    // Forbid form submission for 15 minutes
    // Due to incorrect login attempts
    if (blockedUntil && moment().isBefore(new Date(blockedUntil))) {
      this.renderLockTimeMessage();

      return false;
    }

    if (this.fieldValidation() && this._mounted) {
      let data = getFormData(event.target, { trim: true });

      try {
        const result = await axios(api.users.authorization.login(userType).AXIOS('POST', data));
        const methods = t('keys.tfa');
        const isTfa =
          result.data.hasOwnProperty('preferred_2fa') &&
          methods.some(
            (method) => result.data.hasOwnProperty(method.value) && result.data[method.value]
          );

        localStorage.removeItem(attemptKey);
        localStorage.removeItem(attemptWaitingKey);

        if (isTfa) {
          return this.setState({
            isTfaVerification: true,
            preferred_2fa: result.data.preferred_2fa,
            tfa_google: result.data.tfa_google,
            tfa_phone: result.data.tfa_phone,
          });
        } else {
          // If the token is returned, then redirect to the personal account
          await this.successLogin(result);
        }
      } catch (error) {
        responseError(this, error);

        this.failureLogin(error);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  failureLogin(error) {
    if (error.response && error.response.status < 500) {
      this.attemptResetCounter();
    }
  }

  attemptResetCounter() {
    const { attemptWaitingKey, attemptKey } = this.state;
    let attempt = localStorage.getItem(attemptKey);

    // check the number of attempts
    if (attempt) {
      attempt = parseInt(attempt) + 1;

      localStorage.setItem(attemptKey, JSON.stringify(attempt));

      if (attempt >= 3) {
        localStorage.removeItem(attemptKey);
        localStorage.setItem(attemptWaitingKey, JSON.stringify(moment().add(15, 'minutes')));
      }
    } else {
      localStorage.setItem(attemptKey, JSON.stringify(1));
    }
  }

  cancelTfa() {
    this.setState({
      isTfaVerification: false,
      preferred_2fa: null,
      tfa_google: null,
      tfa_phone: null,
    });
  }

  renderLockTimeMessage() {
    const { t } = this.props;

    notification(
      {
        ...t('notification.authLocked'),
        description: Strings.Compile(t('notification.authLocked.description'), [
          moment(new Date(localStorage.getItem(this.state.attemptWaitingKey))).fromNow(),
        ]),
      },
      'warn'
    );
  }

  renderWebsiteTitle() {
    const { t } = this.props;

    if (defaults.variables.divideCompanyNameIntoTwoLines) {
      return (
        <>
          <h3 className={'weight-bold align-center'}>{t('page.logInTo')}</h3>
          <h2 className={'weight-bold align-center'}>{defaults.website.title}</h2>
        </>
      );
    }

    return (
      <h1 className={'weight-bold align-center'}>
        {Strings.Compile(t('page.logInToName'), [defaults.website.title])}
      </h1>
    );
  }

  renderLoginForm() {
    const { route, t } = this.props;
    const { loading } = this.state;
    const isClient = this.isAccountType();

    return (
      <Form onSubmit={this.handleSubmit}>
        <p className={'align-center'}>{t('page.youBack')}</p>

        {isClient && <div className={'fields'}>{RadioSet(this, ['user_type'])}</div>}

        <div className={'fields'}>
          {InputsSet(this, ['email'])}
          {PasswordSet(this, ['password'])}

          <div className={'buttons align-center'}>
            <NavLink to={`${route}/password-reset`} className={'link'}>
              <Icon type={'key'} />
              <span className={'link'}>{t('page.reset_password')}</span>
            </NavLink>

            {isClient && (
              <NavLink to={`${route}/registration/verification/email`} className={'link'}>
                <Icon type={'user-add'} />
                <span className={'link'}>{t('button.newAccount')}</span>
              </NavLink>
            )}
          </div>
        </div>

        <Button
          htmlType={'submit'}
          type={'primary'}
          size={defaults.antd.button.size}
          loading={loading}
          block
        >
          {t('button.logIn')}
        </Button>
      </Form>
    );
  }

  renderTfaForm() {
    const { userType, t } = this.props;
    const { email, password, preferred_2fa, tfa_google, tfa_phone } = this.state;

    return (
      <div className={'tfa'}>
        <TfaChooseMethod
          type={'check'}
          userType={userType}
          email={email.value}
          password={password.value}
          preferred={preferred_2fa}
          methods={{ tfa_google, tfa_phone }}
          successCallback={this.successLogin}
          errorCallback={this.failureLogin}
        />

        <Divider />

        <div className={'tfa_back'}>
          <Button type={'primary'} size={defaults.antd.button.size} block onClick={this.cancelTfa}>
            {t('button.backToLogin')}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { personalDataLoaded, isTfaVerification } = this.state;
    const { userType, userToken, redirectRoute } = this.props;
    const verificationPages = ['aemi'].indexOf(userType) >= 0;

    if (defaults.isAuthorized(userType) && personalDataLoaded) {
      if (!userToken.status && !verificationPages) {
        verificationController(redirectRoute, null, (result) => {
          if (result.redirect) {
            return <Redirect to={result.redirect} />;
          }
        });
      }

      return <Redirect to={redirectRoute} />;
    }

    return (
      <Wrapper columns={defaults.variables.withTeidoWrapper}>
        <RegistrationBlockWrapper userType={userType}>
          <>
            {this.renderWebsiteTitle()}

            {isTfaVerification ? this.renderTfaForm() : this.renderLoginForm()}
          </>
        </RegistrationBlockWrapper>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ userToken }) => {
  return {
    userToken: userToken,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserToken: (value) => dispatch(getUserToken(value)),
    fetchAllPersonalData: () => dispatch(fetchAllPersonalData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Authorization));

Authorization.propTypes = {
  redirectRoute: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  userToken: PropTypes.object.isRequired,
  getUserToken: PropTypes.func.isRequired,
  fetchAllPersonalData: PropTypes.func,
  t: PropTypes.func.isRequired,
};
