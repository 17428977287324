import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { TfaEditModal } from '@organisms/personal-profile/ProfileTfa';
import notification from '@helps/notification';
import { changePersonalDataField } from '@features/personalSlice';

import api from 'API';
import { responseError } from 'FIELDS';
import { tConsume } from 'TRANSLATION';
import { Strings } from 'HELPERS';

const TfaMethod = ({ method, isActive, isActiveMethodSingle, isPinned, userType, t }) => {
  const [preferredLoading, setPreferredLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const { value: original_value, short_value } = method;
  const translatedTitle = t(`component.tfa.${original_value}_title`);
  const dispatch = useDispatch();

  const handleTogglePreferred = async () => {
    const data = { preferred: short_value };

    if (isPinned) return notification(t('component.tfa.already_pin'));
    if (!isActive) return notification(t('component.tfa.edit_before_pin'));

    try {
      setPreferredLoading(true);
      const result = await axios(api.tfa.preferred(userType).AXIOS('POST', data));

      dispatch(changePersonalDataField(result.data));
      notification(
        Strings.Compile(t('component.tfa.method_preferred'), [translatedTitle]),
        'success'
      );
    } catch (error) {
      responseError(null, error);
    } finally {
      setPreferredLoading(false);
    }
  };

  const handleStopMethod = async (event) => {
    event.stopPropagation();
    let CONFIG;

    if (short_value === 'google') {
      CONFIG = api.tfa.unbindGoogleTfa().AXIOS('POST');
    }

    if (short_value === 'phone') {
      return notification(
        Strings.Compile(t('component.tfa.cant_delete_default_method'), [translatedTitle]),
        'info'
      );
    }

    try {
      setStopLoading(true);
      const result = await axios(CONFIG);

      dispatch(changePersonalDataField(result.data));
    } catch (error) {
      responseError(null, error);
    } finally {
      setStopLoading(false);
    }
  };

  return (
    <div className={'tfaItem'}>
      <div className={'tfaItem_title'}>{translatedTitle}</div>
      {isActive ? (
        <Button.Group>
          <Tooltip placement={'left'} title={t(`component.tfa.${isPinned ? 'unpin' : 'pin'}`)}>
            <Button
              loading={preferredLoading}
              disabled={preferredLoading || stopLoading}
              icon={'pushpin'}
              className={'tfaItem_controls_action'}
              type={isPinned ? 'primary' : 'default'}
              onClick={handleTogglePreferred}
            />
          </Tooltip>
          <Popconfirm
            title={t('component.tfa.stop_method')}
            okText={t('button.yes')}
            cancelText={t('button.no')}
            onConfirm={handleStopMethod}
          >
            <Button
              loading={stopLoading}
              disabled={
                preferredLoading || stopLoading || isActiveMethodSingle || short_value === 'phone'
              }
              icon={'stop'}
              className={'tfaItem_controls_action'}
              type={'primary'}
            />
          </Popconfirm>
        </Button.Group>
      ) : (
        <TfaEditModal
          methodShortName={short_value}
          disabled={preferredLoading || stopLoading || short_value === 'phone'}
        />
      )}
    </div>
  );
};

export default tConsume(TfaMethod);

TfaMethod.propTypes = {
  method: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
    short_value: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  isActiveMethodSingle: PropTypes.bool,
  isPinned: PropTypes.bool,
  userType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
