export default {
  digits: /(^$|^[-]?(\d+([.]\d*)?(e[+-]?\d+)?|[.]\d+(e[+-]?\d+)?)$)/,
  percents: /^100(\.(0{1,2}))?$|^\d{1,2}(\.\d{1,2})?$/,
  number: /\d+(\.\d{1,2})?/,
  // for checking fiat (validate input value)
  currency: /^(?:0|[1-9][0-9]*)(?:\.[0-9]{1,2})?$/,
  // cryptocurrency input checking (accept or not the value from input)
  cryptoCurrencyAccept: /^$|^(?:0|[1-9][0-9]*)\.?((?<=\.)\d{1,7})?$/,
  // for checking cryptocurrency (validate input value)
  cryptoCurrency: /^(?:0|[1-9][0-9]*)(?:\.[0-9]{1,7})?$/,
};
