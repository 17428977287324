import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locale: null,
  status: false,
  tfa: false,
  token: null,
  tokenType: null,
  verified: false,
};

const userTokenSlice = createSlice({
  name: 'userToken',
  initialState: initialState,
  reducers: {
    getUserToken(state, action) {
      state.locale = action.payload.locale || state.locale;
      state.status = action.payload.status || state.status;
      state.tfa = action.payload.tfa || state.tfa;
      state.token = action.payload.token || state.token;
      state.tokenType = action.payload.tokenType || state.tokenType;
      state.verified = action.payload.verified || state.verified;
    },
    updateUserToken(state, action) {
      state.status = action.payload;
    },
    clearUserToken() {
      return initialState;
    },
  },
});

export const { getUserToken, updateUserToken, clearUserToken } = userTokenSlice.actions;
export default userTokenSlice.reducer;
